<template>
  <div>
    <div class="profile-wrapper-main">
      <div class="container-main">
        <h1 class="main-title-page">Профиль</h1>
      </div>
    </div>
    <div class="container-main position-relative" style="margin-bottom: 40rem">
      <div class="profile-main-block">
        <div class="profile-avatar-block">


          <div class="profile-user">
            <div class="profile-user-name">Сагинбаева Сауле</div>
            <div class="profile-user-email">saule15@mail.ru</div>
          </div>
          <button @click="$bvModal.show('avatar-select')" class="avatar-profile-upload">Сменить аватар</button>
        </div>
        <div class="profile-info-block">
<!--          <div class="profile-tabs">-->
<!--            <div :class="{'profile-tab-active':currentTab=='main'}" @click="currentTab='main'" class="profile-tab">-->
<!--              Основное-->
<!--            </div>-->
<!--            <div :class="{'profile-tab-active':currentTab=='password'}" @click="currentTab='password'"-->
<!--                 class="profile-tab">Смена пароля-->
<!--            </div>-->
<!--            <div :class="{'profile-tab-active':currentTab=='settings'}" @click="currentTab='settings'"-->
<!--                 class="profile-tab">Настройка уведомлений-->
<!--            </div>-->
<!--          </div>-->
          <div class="strong-password-recommendation">Придумайте надежный пароль
            <div class="lock-key-profile"></div>
          </div>
          <div class="profile-wrapper-content">
            <div v-if="currentTab=='main'">
              <div class="profile-inputs-wrapper">
                <div class="profile-inputs-column">
                  <div class="smart-oqu-group-theme">
                    <label class="smart-oqu-label-theme" for="password">Новый пароль</label>
                    <input autocomplete="false" class="smart-oqu-input-theme" type="text" name=""
                           placeholder="Новый пароль"
                           id="password">
                  </div>
                  <div class="smart-oqu-group-theme">
                    <label class="smart-oqu-label-theme" for="password">Новый пароль</label>
                    <input autocomplete="false" class="smart-oqu-input-theme" type="text" name=""
                           placeholder="Новый пароль"
                           id="password">
                  </div>
                </div>
                <div class="profile-inputs-column">
                  <div class="smart-oqu-group-theme">
                    <label class="smart-oqu-label-theme" for="email">Электронная почта</label>
                    <input autocomplete="false" class="smart-oqu-input-theme" type="text" name=""
                           placeholder="Эллектронная почта"
                           id="email">
                  </div>
                  <div class="smart-oqu-group-theme">
                    <label class="smart-oqu-label-theme" for="phone">Мобильный телефон</label>
                    <input autocomplete="false" class="smart-oqu-input-theme" type="text" name=""
                           placeholder="Мобильный телефон" id="phone">
                  </div>
                </div>
              </div>
              <div class="profile-update">Обновить профиль</div>

            </div>
            <div v-if="currentTab=='password'">
              <div class="profile-inputs-wrapper">
                <div class="profile-inputs-column">
                  <div class="password-required-name">Ваш пароль должен:</div>

                  <div class="recommendation-block">
                    Состоять из более, чем 8 символов
                  </div>
                  <div class="recommendation-block">
                    Содержать как минимум одну заглавную букву
                  </div>
                  <div class="recommendation-block">
                    Содержать как минимум одну цифру
                  </div>
                  <div class="recommendation-block">
                    Содержать специальные символы
                  </div>

                </div>
                <div class="profile-inputs-column">
                  <div class="strong-password-recommendation">Придумайте надежный пароль
                    <div class="lock-key-profile"></div>
                  </div>
                  <div class="smart-oqu-group-theme">
                    <label class="smart-oqu-label-theme" for="password">Новый пароль</label>
                    <input autocomplete="false" class="smart-oqu-input-theme" type="text" name=""
                           placeholder="Новый пароль"
                           id="password">
                  </div>
                  <div class="smart-oqu-group-theme">
                    <label class="smart-oqu-label-theme" for="password-repeat">Повторите пароль</label>
                    <input autocomplete="false" class="smart-oqu-input-theme" type="text" name=""
                           placeholder="Повторите пароль" id="password-repeat">
                  </div>
                  <div class="profile-update password-update">Обновить профиль</div>
                </div>
              </div>


            </div>
            <div v-if="currentTab=='settings'">
              <div class="profile-inputs-wrapper">
                <div class="profile-inputs-column">
                  <div class="strong-password-recommendation">Допуск уведомлений
                    <div class="lock-key-profile"></div>
                  </div>
                  <div class="switch-form-main">
                    <div>Системные (личный кабинет)</div>
                    <div><label class="form-switch">
                      <input type="checkbox">
                      <i></i>
                    </label></div>
                  </div>
                  <div class="switch-form-main">
                    <div>СМС уведомления</div>
                    <div><label class="form-switch">
                      <input type="checkbox">
                      <i></i>
                    </label></div>
                  </div>
                  <div class="switch-form-main">
                    <div>Электронная почта</div>
                    <div><label class="form-switch">
                      <input type="checkbox">
                      <i></i>
                    </label></div>
                  </div>
                </div>
                <div class="profile-inputs-column">
                  <div style="margin-top: 0" class="smart-oqu-group-theme">
                    <label class="smart-oqu-label-theme" for="lang">Язык уведомлений</label>
                    <select autocomplete="false" class="smart-oqu-input-theme" type="text" name=""
                            placeholder="Язык уведомлений"
                            id="lang">
                      <option>{{$t('ru')}}</option>
                      <option>{{$t('kz')}}</option>
                    </select>
                  </div>

                  <div class="notifications-images"></div>

                </div>
              </div>


            </div>
          </div>
          <div class="profile-logout-button">
            <span> Выйти из системы</span>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="avatar-select" size="xl" hide-footer hide-header hide-header-close centered>
      <div class="profile-modal-wrapper">
        <button @click="$bvModal.hide('avatar-select')" class="close-modal-profile"></button>
        <h1>Сменить аватар</h1>
        <div class="avatar-upload-block-wrapper">

          <div class="avatar-profile-example">
            <div style="background-image: url('./avatars/01-business woman.png')" class="avatar-profile-image"></div>
            <div style="background-image: url('./avatars/09-student.png')" class="avatar-profile-image"></div>
            <div style="background-image: url('./avatars/03-confident woman.png')" class="avatar-profile-image"></div>
            <div style="background-image: url('./avatars/02-lady.png')" class="avatar-profile-image"></div>
            <div style="background-image: url('./avatars/22-nerd bot.png')" class="avatar-profile-image"></div>
            <div style="background-image: url('./avatars/19-business man.png')" class="avatar-profile-image"></div>
            <div style="background-image: url('./avatars/27-basic people.png')" class="avatar-profile-image"></div>
            <div style="background-image: url('./avatars/birge-oqu.png')" class="avatar-profile-image"></div>
            <div style="background-image: url('./avatars/11-raccoon.png')" class="avatar-profile-image"></div>
            <div style="background-image: url('./avatars/05-lion.png')" class="avatar-profile-image"></div>
            <div style="background-image: url('./avatars/03-rabbit.png')" class="avatar-profile-image"></div>
            <div style="background-image: url('./avatars/04-bear.png')" class="avatar-profile-image"></div>

          </div>
          <div class="avatar-upload">
            <span class="your-avatar">Ваш аватар</span>
            <div class="avatar-round"></div>
            <button @click="$bvModal.show('avatar-select')" class="avatar-profile-upload-logo">Загрузить фото</button>

          </div>
        </div>
        <div class="button-profile-safe">
          <button>Сохранить</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>

import Vue from 'vue';

export default {
  name: 'Tutor',
  data() {
    return {
      currentTab: 'main'
    }
  },
  methods: {}

}
</script>
